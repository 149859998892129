import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
  IconButton,
  TablePagination,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockResetIcon from '@mui/icons-material/LockReset';
import { getAllUsers, deleteUser } from '../services/userService';
import { getUserFromToken } from '../services/authService';
import { manageUserPassword } from '../services/accountService';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);

  // Manage Password Modal State
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [passwordResetUser, setPasswordResetUser] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const user = getUserFromToken(); // Get current user's roles
  const isSysAdmin = user?.roles?.includes('sys-admin'); // Check if the user is a sys-admin
  const isAdmin = user?.roles?.includes('admin'); // Check if the user is an admin

  useEffect(() => {
    fetchUsers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await getAllUsers();
      setLoading(false);
      let sortedUsers = data.sort((a, b) => a.name?.localeCompare(b.name));

      // Apply role-based filtering
      if (isSysAdmin) {
        // Sys-admin sees all users
        setUsers(sortedUsers);
        setFilteredUsers(sortedUsers);
      } else if (isAdmin) {
        // Admin sees only 'customer' and 'admin' roles
        const filtered = sortedUsers.filter((user) =>
          user.userRoles.some((role) =>
            ['customer', 'admin'].includes(role.role?.name)
          )
        );
        setUsers(filtered);
        setFilteredUsers(filtered);
      } else {
        // No access to user list
        setUsers([]);
        setFilteredUsers([]);
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar('Error fetching users', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      try {
        await deleteUser(id);
        fetchUsers();
        enqueueSnackbar('User deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting user', { variant: 'error' });
      }
    }
  };

  const handleSearch = (e) => {
    const term = e.target?.value?.toLowerCase() || '';
    setSearchTerm(term);
    const filtered = users.filter(
      (user) =>
        user.name?.toLowerCase().includes(term) ||
        user.email?.toLowerCase().includes(term) ||
        user.userRoles.some((role) =>
          role.role?.name?.toLowerCase().includes(term)
        )
    );
    setFilteredUsers(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Open Password Reset Modal
  const handleOpenPasswordModal = (user) => {
    setPasswordResetUser(user);
    setNewPassword('');
    setPasswordModalOpen(true);
  };

  // Close Password Reset Modal
  const handleClosePasswordModal = () => {
    setPasswordResetUser(null);
    setNewPassword('');
    setPasswordModalOpen(false);
  };

  // Handle Password Reset Submission
  const handlePasswordReset = async () => {
    if (!newPassword) {
      enqueueSnackbar('Password cannot be empty', { variant: 'warning' });
      return;
    }
    setIsSubmitting(true);
    try {
      await manageUserPassword({ userId: passwordResetUser.id, newPassword });
      enqueueSnackbar('Password reset successfully', { variant: 'success' });
      handleClosePasswordModal();
    } catch (error) {
      enqueueSnackbar('Error resetting password', { variant: 'error' });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: '20px', margin: '20px' }}>
      {/* Search Bar */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" sx={{ width: '70%' }}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <TextField
            placeholder="Search by name, email, or roles"
            inputProps={{ 'aria-label': 'search users' }}
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/users/create')}
            sx={{ marginRight: '10px' }}
          >
            Add User
          </Button>
          <IconButton onClick={fetchUsers}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="user table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.userRoles
                      .map((role) => role.role?.name || 'Unknown Role')
                      .join(', ')}
                  </TableCell>
                  <TableCell>{user.active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>{new Date(user.createdAt).toLocaleString()}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/users/edit/${user.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Manage Password">
                      <IconButton color="primary" onClick={() => handleOpenPasswordModal(user)}>
                        <LockResetIcon />
                      </IconButton>
                    </Tooltip>
                    {/* Render Delete only for sys-admin */}
                    {isSysAdmin && (
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(user.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    <Dialog open={isPasswordModalOpen} onClose={handleClosePasswordModal}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <TextField label="New Password" type="password" fullWidth value={newPassword} onChange={(e) => setNewPassword(e.target.value)} margin="normal" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordModal}>Cancel</Button>
          <Button onClick={handlePasswordReset} color="primary" variant="contained" disabled={isSubmitting}>{isSubmitting ? <CircularProgress size={24} /> : 'Reset Password'}</Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default UserList;
