import React from 'react'
import Heading from './Heading'

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"> 
            <g clipPath="url(#clip0_4076_9754)"> 
                <path d="M16.5 8.3099V8.9999C16.4991 10.6172 15.9754 12.1909 15.007 13.4863C14.0386 14.7816 12.6775 15.7293 11.1265 16.1878C9.57557 16.6464 7.91794 16.5913 6.40085 16.0308C4.88376 15.4703 3.58849 14.4345 2.70822 13.0777C1.82795 11.7209 1.40984 10.1159 1.51626 8.50213C1.62267 6.88832 2.24791 5.35214 3.29871 4.1227C4.34951 2.89326 5.76959 2.03644 7.34714 1.68001C8.92469 1.32358 10.5752 1.48665 12.0525 2.1449" stroke="#78939A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> 
                <path d="M16.5 3L9 10.5075L6.75 8.2575" stroke="#78939A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> 
            </g> 
            <defs> 
                <clipPath id="clip0_4076_9754"> 
                    <rect width="18" height="18" fill="white" /> 
                </clipPath> 
            </defs>
        </svg>
    )
}

const features = [
    {
        title: "Comprehensive Risk Management",
        description: [
            "Equity protection options.",
            "Per-trade stop-loss (SL) settings for controlled risk."
        ],
        imgSrc: "/assets/feature-1.png",
        imgAlt: "feature-1",
        buttonText: "CONTACT US",
        reverse: false
    },
    {
        title: "AI-Driven Decision Making",
        description: [
            "Real-time market data analysis.",
            "Evaluation of volatility, trends, and economic conditions.",
            "Advanced trade risk identification for safer trading decisions."
        ],
        imgSrc: "/assets/feature-2.png",
        imgAlt: "feature-2",
        buttonText: "CONTACT US",
        reverse: true
    },
    {
        title: "Transparent, Affordable Pricing",
        description: [
            "No hidden fees, buy-ins, or revenue sharing.",
            "Simple monthly fee based on account balance.",
            "Full ownership of profits."
        ],
        imgSrc: "/assets/feature-3.png",
        imgAlt: "feature-3",
        buttonText: "CONTACT US",
        reverse: false
    }
]

export default function FeatureSection() {
    return (
        <div className='feature-bg text-white' id='feature-section'>
            <div className='container mx-auto px-4'>
                <section className="flex flex-col items-center justify-center lg:space-y-16 space-y-10 px-6 py-16 text-white">
                    <Heading title={"Features"} dashTitle={"Features"} />

                    {features.map((feature, index) => (
                        <div key={index} className={`flex w-full max-w-6xl  items-center md:flex-nowrap flex-wrap ${feature.reverse ? 'md:flex-row-reverse' : ''}`}>
                            <div className="flex justify-center lg:w-2/4 md:w-2/5 w-full p-2">
                                <img src={feature.imgSrc} alt={feature.imgAlt} className="w-full" />
                            </div>
                            <div className='flex flex-col lg:flex-1 md:flex-1 w-full'>
                                <h2 className="mt-2 text-left font-bold lg:text-3xl md:text-2xl text-2xl">{feature.title}</h2>
                                <ul className="mt-4 space-y-2 text-sm text-gray-300">
                                    {feature.description.map((desc, i) => (
                                        <li key={i} className="flex items-center">
                                            <span className="mr-2">
                                                <CheckIcon />
                                            </span>
                                            {desc}
                                        </li>
                                    ))}
                                </ul>
                                <div className="mt-6 flex justify-center md:justify-start">
                                    <a href='#contact-section' className="rounded-full bg-[#87A3A8] px-6 py-3 text-sm font-medium text-black shadow-md transition hover:bg-opacity-80">{feature.buttonText}</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </section>
            </div>
        </div>
    )
}
