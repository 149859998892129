import React from 'react';
import FrontLayout from '../../components/FrontLayout';
import Header from '../../components/FrontLayout/Header';
import HeroSection from '../../components/ProductPage/HeroSection';
import FeatureSection from '../../components/ProductPage/FeatureSection';

const ProfitPulsePage = () => {

  return (
    <>
      <FrontLayout>
        <div className=" text-white relative pamm-hero-bg">
          <div className='container mx-auto lg:px-4 px-0'>
            <Header />
            <HeroSection title='The Next Evolution in AI-Powered Trading' subtitle={`PROFITPULSE is an advanced, AI-driven trading system designed for precision, safety, and portability in today’s dynamic forex markets. Developed by Fintech Strategies, this expert advisor (EA) blends real-time market analysis, risk management, and automation to help traders maximize ports while minimizing unnecessary risks.
                    Unlike traditional trading bots that chase trades and rely on static strategies, PROFITPULSE adapts dynamically evaluating market conditions, external factors, and economic events before executing trades.
                    Whether you're a beginner or a seasoned trader, PROFITPULSE provides the tools and automation needed for SMART TRADING, SMARTER RETURNS.`} />
          </div>
        </div>

        <FeatureSection />
        
      </FrontLayout>
    </>
  );
};

export default ProfitPulsePage;