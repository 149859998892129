import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
  IconButton,
  TablePagination,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getAllApplications, deleteApplication } from '../services/applicationService';
import { getUserFromToken } from '../services/authService';

const ApplicationList = () => {
  const [applications, setApplications] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const user = getUserFromToken(); // Get current user's roles
  const isSysAdmin = user?.roles?.includes('sys-admin'); // Check if the user is a sys-admin

  useEffect(() => {
    fetchApplications();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchApplications = async () => {
    try {
      const data = await getAllApplications();
      const sortedApplications = data.sort((a, b) => a.name.localeCompare(b.name));
      setApplications(sortedApplications);
      setFilteredApplications(sortedApplications);
    } catch (error) {
      enqueueSnackbar('Error fetching applications', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this application?');
    if (confirmed) {
      try {
        await deleteApplication(id);
        fetchApplications();
        enqueueSnackbar('Application deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting application', { variant: 'error' });
      }
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = applications.filter(
      (app) =>
        app.name.toLowerCase().includes(term) ||
        app.description.toLowerCase().includes(term)
    );
    setFilteredApplications(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper elevation={3} sx={{ padding: '20px', margin: '20px' }}>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" sx={{ width: '70%' }}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <TextField
            placeholder="Search by name or description"
            inputProps={{ 'aria-label': 'search applications' }}
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
          />
        </Box>
        <Box display="flex" alignItems="center">
          {/* Render Add Application button only for sys-admin */}
          {isSysAdmin && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/applications/create')}
              sx={{ marginRight: '10px' }}
            >
              Add Application
            </Button>
          )}
          <IconButton onClick={fetchApplications}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="application table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Terminal ID</TableCell>
              <TableCell>Active</TableCell>
              {isSysAdmin && (
              <TableCell>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredApplications
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((app) => (
                <TableRow key={app.id}>
                  <TableCell>{app.name}</TableCell>
                  <TableCell>{app.description}</TableCell>
                  <TableCell>{app.terminalId}</TableCell>
                  <TableCell>{app.active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    {/* Render Edit button only for sys-admin */}
                    {isSysAdmin && (
                      <Tooltip title="Edit">
                        <IconButton color="primary" onClick={() => navigate(`/applications/edit/${app.id}`)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* Render Delete button only for sys-admin */}
                    {isSysAdmin && (
                      <Tooltip title="Delete">
                        <IconButton color="secondary" onClick={() => handleDelete(app.id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredApplications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default ApplicationList;
