import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, CircularProgress, Alert } from '@mui/material';
import { activateUser } from '../services/accountService';

const ActivateUser = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState('');
  const activateToken = searchParams.get('token');

  useEffect(() => {
    const activateAccount = async () => {
      if (!activateToken) {
        setSuccess(false);
        setMessage('Activation token is missing.');
        setLoading(false);
        return;
      }

      try {
        const response = await activateUser(activateToken);
        setSuccess(true);
        setMessage(response.message || 'User activation successful.');
      } catch (error) {
        setSuccess(false);
        setMessage(error.response?.data?.message || 'User activation failed.');
      } finally {
        setLoading(false);
      }
    };

    activateAccount();
  }, [activateToken]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        padding: 2,
        textAlign: 'center',
      }}
    >
      {loading ? (
        <CircularProgress />
      ) : success ? (
        <Alert severity="success" sx={{ mb: 2 }}>
          {message}
        </Alert>
      ) : (
        <Alert severity="error" sx={{ mb: 2 }}>
          {message}
        </Alert>
      )}
    </Box>
  );
};

export default ActivateUser;
