/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { isAuthenticated } from '../../services/authService';

export default function Header() {
    const userLoggedIn = isAuthenticated();
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const MenuSection = () => {
        return (
            <>
                <div className='w-full lg:flex justify-center'>
                    <nav className="flex flex-col flex-wrap lg:flex-row xl:flex-nowrap gap-4">
                        <a href="/" className="hover:text-gray-400">Home</a>
                        <a href="/#hero-section" className="hover:text-gray-400 text-nowrap">About us</a>
                        <a href="/#sec-overview" className="hover:text-gray-400">Benefits</a>
                        <a href="/#feature-section" className="hover:text-gray-400">Features</a>
                        <a href="/#products-section" className="hover:text-gray-400">Products</a>
                        <a href="https://myfxbook.com/" target='blank' className="hover:text-gray-400">Performance</a>
                        <a href="/pamm" className="hover:text-gray-400">PAMM</a>
                        <a href="/#contact-section" className="hover:text-gray-400">Contact</a>
                    </nav>
                </div>
                <div className="flex flex-col lg:flex-row items-center gap-6 mt-6 lg:mt-0">
                    <div className="flex items-center gap-3">
                        <a href='https://instagram.com/fintech_strategies' target='_blank' rel="noreferrer"><InstagramIcon className="text-white cursor-pointer hover:text-gray-400" /></a>
                        <span className='bg-white w-[1px] h-[30px] lg:hidden'></span>
                        <a href='https://facebook.com/fintechstrategiesllc' target='_blank' rel="noreferrer"><FacebookIcon className="text-white cursor-pointer hover:text-gray-400" /></a>
                    </div>
                    <a href={userLoggedIn ? '/dashboard' : '/login'} className="bg-[#89A8B0] hover:bg-[#89a8b0e5] px-4 py-3 rounded-full text-black font-medium whitespace-nowrap">
                        Investor Dashboard
                    </a>
                </div>
            </>
        )
    }

    return (
        <header className="relative flex justify-between items-center lg:items-start gap-10 p-4">
            <div className="w-2/5 md:w-1/5">
                <img src="/assets/logo.png" alt="logo" className="w-full" />
            </div>
            <div className="lg:hidden">
                <button onClick={toggleMenu} className="text-white">
                    {menuOpen ? <CloseIcon /> : <MenuIcon />}
                </button>
            </div>
            <div className={`gap-10 hidden lg:flex w-4/5 relative translate-x-0 bg-transparent bg-opacity-0 flex-row pt-10 items-center justify-between`}>
                <MenuSection />
            </div>

            <div className={`lg:hidden z-40 fixed top-0 left-0 w-full h-full transition-transform duration-300 ${menuOpen ? 'translate-x-0' : '-translate-x-full'}`} onClick={toggleMenu}>
                <div className='bg-black bg-opacity-90 w-[70%] sm:w-[50%] h-full p-10'>
                    <MenuSection />
                </div>
            </div>
        </header>
    )
}
