import React from 'react'

export default function HeroSection() {
    return (
        <section className="flex lg:flex-row flex-col-reverse items-start justify-between pt-0 lg:pt-16 pb-0 lg:pb-16" id='hero-section'>
            <div className="lg:w-2/5 md:w-full w-full text-center lg:text-left pt-12 lg:px-0 px-5 relative sm:hero-bg pb-16 lg:pb-0">
                <h1 className="text-3xl lg:text-6xl font-bold">
                    Revolutionize your trading with our custom AI expert advisors
                </h1>
                <p className="mt-6 text-gray-400">
                    Built for traders who want consistent performance without manual intervention, our EA's eliminate the complexities of active trading. They are tailored to deliver precision, low drawdowns, and reliable results while prioritizing your account's safety
                </p>
                <a href='#contact-section' className="bg-[#89A8B0] hover:bg-[#89a8b0e5] px-10 py-3 inline-flex mt-6 rounded-full text-black font-medium">
                    Contact us
                </a> 
                <div className="absolute inset-0 hero-mob-mask lg:hidden"></div>
            </div>
            <div className="lg:w-3/5 md:w-full w-full flex justify-center">
                <img src='/assets/hero-vector.png' alt='hero' className="lg:block hidden w-full" />
                <img src='/assets/hero-mob-vector.png' alt='hero' className="lg:hidden sm:block w-100" />
            </div>
        </section>
    )
}
