import React from 'react'
import Footer from './Footer'

export default function FrontLayout({ children }) {
    return (
        <>
            {children}
            <Footer />
        </>
    )
}
