
import "swiper/css";
import "swiper/css/navigation";
import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import Heading from './Heading'

// JSON data for the testimonials
const testimonials = [
    {
        testimonial: "Been using this Algo for the last 2 months and have been getting close to 20% a month with zero losses. Great customer service and very little initial set up and maintenance. There are days it doesn't trade due to market volatility but I'd rather it be safe then blow my account. Definitely one of the better Algos I have used. Happy so far!",
        name: "Chau Tran",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/615ce5d6d8211e0012c62878/73x73.png"
    },
    {
        testimonial: "I have been using these guys for several months, and I must say, it’s been a game-changer in my trading. The software is very easy to use, intuitive, efficient, and accurate. Its real-time analytics and adaptability to market conditions really makes you feel safe providing consistent performance, even in volatile markets.",
        name: "Cathy Stinchfield",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/64ef72e10ed31000112f8e64/73x73.png"
    },
    {
        testimonial: "I have been using this algo for the last couple of months and the results have been amazing! I really just set it and forget it and check in every now and then to see how much money it has made me. I am also impressed of how safe it is when analyzing the market and ensuring when it gets into a trade there is a high win probability.",
        name: "Alexander Antosek",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/66ab7ea3fd4baa38b489aa97/73x73.png"
    },
    {
        testimonial: "Been using this Algo for the last 2 months and have been getting close to 20% a month with zero losses. Great customer service and very little initial set up and maintenance. There are days it doesn't trade due to market volatility but I'd rather it be safe then blow my account. Definitely one of the better Algos I have used. Happy so far!",
        name: "Chau Tran",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/615ce5d6d8211e0012c62878/73x73.png"
    },
    {
        testimonial: "I have been using these guys for several months, and I must say, it’s been a game-changer in my trading. The software is very easy to use, intuitive, efficient, and accurate. Its real-time analytics and adaptability to market conditions really makes you feel safe providing consistent performance, even in volatile markets.",
        name: "Cathy Stinchfield",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/64ef72e10ed31000112f8e64/73x73.png"
    },
    {
        testimonial: "I have been using this algo for the last couple of months and the results have been amazing! I really just set it and forget it and check in every now and then to see how much money it has made me. I am also impressed of how safe it is when analyzing the market and ensuring when it gets into a trade there is a high win probability.",
        name: "Alexander Antosek",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/66ab7ea3fd4baa38b489aa97/73x73.png"
    },
    {
        testimonial: "Been using this Algo for the last 2 months and have been getting close to 20% a month with zero losses. Great customer service and very little initial set up and maintenance. There are days it doesn't trade due to market volatility but I'd rather it be safe then blow my account. Definitely one of the better Algos I have used. Happy so far!",
        name: "Chau Tran",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/615ce5d6d8211e0012c62878/73x73.png"
    },
    {
        testimonial: "I have been using these guys for several months, and I must say, it’s been a game-changer in my trading. The software is very easy to use, intuitive, efficient, and accurate. Its real-time analytics and adaptability to market conditions really makes you feel safe providing consistent performance, even in volatile markets.",
        name: "Cathy Stinchfield",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/64ef72e10ed31000112f8e64/73x73.png"
    },
    {
        testimonial: "I have been using this algo for the last couple of months and the results have been amazing! I really just set it and forget it and check in every now and then to see how much money it has made me. I am also impressed of how safe it is when analyzing the market and ensuring when it gets into a trade there is a high win probability.",
        name: "Alexander Antosek",
        designation: "Customer",
        company: "",
        image: "https://user-images.trustpilot.com/66ab7ea3fd4baa38b489aa97/73x73.png"
    }
]

export default function TestimonialSection() {
    return (
        <div className="testimonial-bg text-white overflow-hidden">
            <div className="py-16">
                <div className="container mx-auto px-4 pb-6">
                    <Heading title={"What our client says"} dashTitle={"Testimonials"} />
                </div>
                <div className="lg:px-0 px-4">
                    <Swiper
                        modules={[Autoplay, Navigation]}
                        centeredSlides={true}
                        spaceBetween={30}
                        slidesPerView={3.5}
                        navigation={true}
                        autoplay={{ delay: 2500 }}
                        loop={true}
                        className="mySwiper"
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                                spaceBetween: 20
                            },
                            480: {
                                slidesPerView: 1.5,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2.5,
                                spaceBetween: 30
                            },
                            1024: {
                                slidesPerView: 3.5,
                                spaceBetween: 30
                            },
                            1440: {
                                slidesPerView: 4.5,
                                spaceBetween: 30
                            }
                        }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index} className="testimonial-gradient p-6 rounded-lg shadow-lg">
                                <a href="https://www.trustpilot.com/review/fintechstrategies.ai" target="blank">
                                    <p className="mb-4">"{testimonial.testimonial}"</p>
                                    <div className="flex items-center">
                                        <img alt={`Portrait of ${testimonial.name}`} className="w-12 h-12 rounded-full mr-4" src={testimonial.image} />
                                        <div>
                                            <p className="font-bold">{testimonial.name}</p>
                                            <p className="text-sm text-gray-400">{testimonial.designation}{testimonial.company !== "" ? " | " + testimonial.company : ""}</p>
                                        </div>
                                    </div>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="hidden lg:block">
                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={30}
                        centeredSlides={true}
                        slidesPerView={3.5}
                        slidesOffsetBefore={300}
                        slidesOffsetAfter={300}
                        autoplay={{ delay: 3000 }}
                        loop={true}
                        className="mySwiper mt-8"
                        breakpoints={{
                            320: {
                                slidesPerView: 1.5,
                                spaceBetween: 20
                            },
                            480: {
                                slidesPerView: 1.5,
                                spaceBetween: 20
                            },
                            768: {
                                slidesPerView: 2.5,
                                spaceBetween: 30
                            },
                            1024: {
                                slidesPerView: 3.5,
                                spaceBetween: 30
                            },
                            1440: {
                                slidesPerView: 4.5,
                                spaceBetween: 30
                            }
                        }}
                    >
                        {testimonials.map((testimonial, index) => (
                            <SwiperSlide key={index} className="testimonial-gradient p-6 rounded-lg shadow-lg">
                                <a href="https://www.trustpilot.com/review/fintechstrategies.ai" target="blank">
                                    <p className="mb-4">"{testimonial.testimonial}"</p>
                                    <div className="flex items-center">
                                        <img alt={`Portrait of ${testimonial.name}`} className="w-12 h-12 rounded-full mr-4" src={testimonial.image} />
                                        <div>
                                            <p className="font-bold">{testimonial.name}</p>
                                            <p className="text-sm text-gray-400">{testimonial.designation}{testimonial.company !== "" ? " | " + testimonial.company : ""}</p>
                                        </div>
                                    </div>
                                </a>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    )
}
