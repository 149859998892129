import React, { useState } from 'react';
import { sendContactUsInquiry } from '../../services/accountService';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import ReCAPTCHA from "react-google-recaptcha";
import config from '../../config';

export default function ContactUsSection() {
    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        productInterest: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [showRecaptcha, setShowRecaptcha] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const validate = () => {
        const errors = {};
        if (!formData.name) errors.name = "Name is required";
        if (!formData.phoneNumber) errors.phoneNumber = "Phone number is required";
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        if (!formData.message) errors.message = "Message is required";
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length === 0) {
            setShowRecaptcha(true); // Show reCAPTCHA after validation
        }
    };

    const handleRecaptchaSubmit = async () => {
        if (!recaptchaToken) {
            alert("Please complete the reCAPTCHA.");
            return;
        }

        setLoading(true);
        setShowRecaptcha(false);

        try {
            await sendContactUsInquiry({ ...formData, recaptchaToken });
            setSnackbarOpen(true);
            setFormData({
                name: '',
                phoneNumber: '',
                email: '',
                productInterest: '',
                message: '',
            });
        } catch (error) {
            console.error("Failed to submit inquiry:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="testimonial-bg text-white overflow-hidden">
            <div className="container mx-auto px-4">
                <div className="sm:grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-2 py-16 pb-[300px]" id='contact-section'>
                    <div className="p-2">
                        <h2 className="heading mb-1 text-center text-4xl font-semibold text-white md:text-left md:text-4xl lg:text-left lg:text-6xl">
                            <span className="text-[17px] font-[400] text-[#89A8B0] uppercase">Contact Us</span> <br />Get in touch with <br /> us
                        </h2>
                        <br />
                        <img src="https://i.ibb.co/N2ynwfxw/Frame.png" className="mx-0 w-full md:w-[60%] lg:w-[70%]" alt='contact' />
                    </div>
                    <div className="p-4 pt-16 flex justify-center">
                        <form onSubmit={handleSubmit} className="flex min-h-[500px] w-full flex-wrap items-start form-gradient py-10 text-white rounded-[20px] lg:p-7 px-4">
                            <div className="mb-2 px-3 w-[50%]">
                                <label className="mb-2 block w-full text-[16px] font-[300] text-white">Your Name *</label>
                                <input type="text" name="name" value={formData.name} onChange={handleChange}
                                    className="w-full rounded-sm p-3 bg-transparent shadow-[0px_0px_2px_#d9d9d9] drop-shadow-sm" placeholder="Enter Name" />
                                {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                            </div>
                            <div className="mb-2 px-3 w-[50%]">
                                <label className="mb-2 block w-full text-[16px] font-[300] text-white">Your Number *</label>
                                <input type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange}
                                    className="w-full rounded-sm p-3 bg-transparent shadow-[0px_0px_2px_#d9d9d9] drop-shadow-sm" placeholder="Enter Number" />
                                {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
                            </div>
                            <div className="mb-2 px-3 w-[50%]">
                                <label className="mb-2 block w-full text-[16px] font-[300] text-white">Your Email *</label>
                                <input type="text" name="email" value={formData.email} onChange={handleChange}
                                    className="w-full rounded-sm p-3 bg-transparent shadow-[0px_0px_2px_#d9d9d9] drop-shadow-sm" placeholder="Enter Email" />
                                {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                            </div>
                            <div className="mb-2 px-3 w-[50%]">
                                <label className="mb-2 block w-full text-[16px] font-[300] text-white">Product Interest</label>
                                <input type="text" name="productInterest" value={formData.productInterest} onChange={handleChange}
                                    className="w-full rounded-sm p-3 bg-transparent shadow-[0px_0px_2px_#d9d9d9] drop-shadow-sm" placeholder="Enter Product Interest" />
                            </div>
                            <div className="mb-3 w-full px-3">
                                <label className="mb-2 block w-full text-[16px] font-[300] text-white">Message*</label>
                                <textarea name="message" value={formData.message} onChange={handleChange}
                                    className="w-full rounded-sm p-3 bg-transparent shadow-[0px_0px_2px_#d9d9d9] drop-shadow-sm" rows="5" placeholder="Type message here..."></textarea>
                                {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
                            </div>

                            <div className="mb-1 w-full px-3 flex justify-center">
                                {!showRecaptcha ? (
                                    <button type="submit" className="mx-auto w-full rounded-3xl bg-[#89A8B0] text-black py-3 px-6 text-[14px] uppercase flex items-center justify-center"
                                        disabled={loading}>
                                        {loading ? <CircularProgress size={24} color="inherit" /> : "Contact us"}
                                    </button>
                                ) : (
                                    <ReCAPTCHA
                                        sitekey={config.recaptchaKey}
                                        onChange={handleRecaptchaChange}
                                    />
                                )}
                            </div>

                            {showRecaptcha && (
                                <div className="w-full flex justify-center mt-4">
                                    <button onClick={handleRecaptchaSubmit} className="bg-[#89A8B0] text-black py-3 px-6 rounded-3xl text-[14px] uppercase">
                                        Submit Inquiry
                                    </button>
                                </div>
                            )}
                        </form>
                    </div>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={3000}
                        onClose={() => setSnackbarOpen(false)}
                        message="Your inquiry has been submitted successfully!"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    />
                </div>
            </div>
        </div>
    );
}
