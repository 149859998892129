import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, MenuItem, CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
import {
  getLicenseRecord,
  updateLicenseRecord
} from '../services/licenseRecordService';
import { getAllApplications } from '../services/applicationService';
import { getAllSubscriptions } from '../services/subscriptionService';

const LicenseRecordForm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { id } = useParams(); // Retrieve the ID from the URL

  const [formValues, setFormValues] = useState({
    userId: '',
    applicationId: '',
    subscriptionId: '',
    brokerageAccountNumber: '',
    licenseKey: '',
    status: 0,
    numberOfMonthCycle: 1,
    expiresOn: '',
  });
  const [applications, setApplications] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInitialData();
    fetchApplications();
    fetchSubscriptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchInitialData = async () => {
    try {
      if (id) {
        const record = await getLicenseRecord(id);
        setFormValues({
          userId: record.userId || '',
          applicationId: record.applicationId || '',
          subscriptionId: record.subscriptionId || '',
          brokerageAccountNumber: record.licenseBlob?.associatedAccount || '',
          licenseKey: record.licenseBlob?.key || '',
          status: record.active ? 1 : 0,
          numberOfMonthCycle: record.numberOfMonthCycle || 1,
          expiresOn: record.licenseBlob?.expiresOn || '',
        });
      }
    } catch (error) {
      enqueueSnackbar('Error fetching license record', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchApplications = async () => {
    try {
      const data = await getAllApplications();
      setApplications(data);
    } catch (error) {
      enqueueSnackbar('Error fetching applications', { variant: 'error' });
    }
  };

  const fetchSubscriptions = async () => {
    try {
      const data = await getAllSubscriptions();
      setSubscriptions(data);
    } catch (error) {
      enqueueSnackbar('Error fetching subscriptions', { variant: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateLicenseRecord(id, formValues);
      enqueueSnackbar('License record updated successfully', { variant: 'success' });
      navigate('/license-records'); // Navigate back to the list view
    } catch (error) {
      enqueueSnackbar('Error updating license record', { variant: 'error' });
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h6" gutterBottom>
        Edit License Record
      </Typography>
      <TextField
        label="Application"
        name="applicationId"
        value={formValues.applicationId}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        {applications.map((app) => (
          <MenuItem key={app.id} value={app.id}>
            {app.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Subscription"
        name="subscriptionId"
        value={formValues.subscriptionId}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        {subscriptions.map((sub) => (
          <MenuItem key={sub.id} value={sub.id}>
            {sub.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Brokerage Account Number"
        name="brokerageAccountNumber"
        value={formValues.brokerageAccountNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Status"
        name="status"
        value={formValues.status}
        onChange={handleChange}
        fullWidth
        select
        margin="normal"
      >
        <MenuItem value={0}>Inactive</MenuItem>
        <MenuItem value={1}>Active</MenuItem>
      </TextField>
      <TextField
        label="Billing Cycle (Months)"
        name="numberOfMonthCycle"
        type="number"
        value={formValues.numberOfMonthCycle}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Expiration Date"
        name="expiresOn"
        type="date"
        value={formValues.expiresOn ? formValues.expiresOn.split('T')[0] : ''}
        onChange={handleChange}
        fullWidth
        margin="normal"
          InputLabelProps={{
          shrink: true,
          }}
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default LicenseRecordForm;
