import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  Chip,
  Modal,
  Paper,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllRoles } from '../services/roleService';
import { getUserFromToken } from '../services/authService'; // For role-based access
import UserLicenseRecords from './UserLicenseRecords';

const UserForm = ({ userData, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
    roleIds: [],
    active: true,
  });

  const [roles, setRoles] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const user = getUserFromToken(); // Get the current user's details and roles

  useEffect(() => {
    if (userData) {
      setFormValues({
        name: userData.name || '',
        email: userData.email || '',
        roleIds: userData.userRoles.map((role) => role.roleId) || [],
        active: userData.active || false,
      });
    }
    fetchRoles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const fetchRoles = async () => {
    try {
      const allRoles = await getAllRoles();

      // Apply role-based filtering
      let filteredRoles = [];
      if (user?.roles?.includes('sys-admin')) {
        filteredRoles = allRoles; // Sys-admin can see all roles
      } else if (user?.roles?.includes('admin')) {
        filteredRoles = allRoles.filter((role) =>
          ['admin', 'customer'].includes(role.name)
        ); // Admin can see admin and customer roles only
      } else {
        throw new Error('Access denied to roles'); // Other users have no access
      }

      setRoles(filteredRoles);
    } catch (error) {
      enqueueSnackbar('Error fetching roles or access denied', { variant: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleRoleChange = (event, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      roleIds: value.map((role) => role.id),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formValues);
      enqueueSnackbar('User saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error saving user', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5" gutterBottom>
          {userData?.id ? 'Edit User' : 'Create User'}
        </Typography>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
      <TextField
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Email"
        name="email"
        type="email"
        value={formValues.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      {!userData?.id && (
        <TextField
          label="Password"
          name="password"
          type="password"
          value={formValues.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
      )}
      <Autocomplete
        multiple
        options={roles}
        getOptionLabel={(option) => option.name}
        value={roles.filter((role) => formValues.roleIds.includes(role.id))}
        onChange={handleRoleChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip label={option.name} {...getTagProps({ index })} key={option.id} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Roles"
            placeholder="Select roles"
            margin="normal"
          />
        )}
      />
      {userData?.id && (
        <FormControlLabel
          control={
            <Checkbox
              checked={formValues.active}
              onChange={handleChange}
              name="active"
            />
          }
          label="Active"
        />
      )}

      {/* License Records Section */}
      {userData?.id && (
        <Box sx={{ mt: 3 }}>
          <UserLicenseRecords
            userId={userData.id}
            setModalContent={setModalContent}
            openModal={setModalOpen}
          />
        </Box>
      )}

      {/* Modal */}
      <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            padding: 4,
            width: '80%',
          }}
        >
          {modalContent}
        </Paper>
      </Modal>
    </Box>
  );
};

export default UserForm;