import React from 'react'
import Heading from './Heading'

const experts = [
    {
        id: 1,
        imgSrc: "https://i.ibb.co/G4yYctj9/img.png",
        altText: "PROFITPULSE EA",
        title: "PROFITPULSE EA",
        description: "Forex Single Pair AI Expert Advisor (Deployed)",
        link: "/product/profitpulse"
    },
    {
        id: 2,
        imgSrc: "https://i.ibb.co/RGDQHYVW/img-1.png",
        altText: "FUSIONCORE EA",
        title: "FUSIONCORE EA",
        description: "OREX Multi-Pair AI-Driver (In Development)",
        link: "#products-section"
    },
    {
        id: 3,
        imgSrc: "https://i.ibb.co/8D9MJMcX/img-2.png",
        altText: "FUTURESFLUX ALGO",
        title: "FUTURESFLUX ALGO",
        description: "Designed for NASDAQ Futures (Coming Soon)",
        link: "#products-section"
    }
]

export default function AdvisorSection() {
    return (
        <div className="w-full advisor-bg" id='products-section'>
            <div className="container mx-auto px-4 py-16">
                <Heading title={"AI Expert Advisors"} dashTitle={"PRODUCTS"} />
                
                <div className="sm:grid-2 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 mt-9">
                    {experts.map((expert, index) => (
                        <a href={expert.link} key={index}>
                            <div key={index} className="rounded-lg p-6 liner-border">
                                <img src={expert.imgSrc} className="mb-3 w-full" alt={expert.altText} />
                                <h2 className="heading mb-1 text-2xl font-semibold text-[#89A8B0]">{expert.title}</h2>
                                <p className="text-white">{expert.description}</p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    )
}
