import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { getTheme } from '../theme';
import config from '../config';
import ActivateUser from '../components/ActivateUser';

const ActivateUserPage = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/'); // Navigate back to the home page
  };

  const theme = getTheme(config.themeColor); // Get theme from config

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header onLogoClick={handleLogoClick} />
      <ActivateUser />
    </ThemeProvider>
  );
};

export default ActivateUserPage;
