import Heading from "../LandingPage/Heading";

export default function HowItWorks() {
    const steps = [
        {
            step: "Step 1",
            title: "Market Monitoring",
            description: "The EA continuously scans for trade opportunities based on predefined AI-powered strategies."
        },
        {
            step: "Step 2",
            title: "Risk Evaluation",
            description: "Every potential trade is assigned a risk score to determine if it meets the selected risk tolerance."
        },
        {
            step: "Step 3",
            title: "Trade Execution & Management",
            description: "Once a trade meets all safety checks, it is automatically executed, monitored, and optimized."
        }
    ];

    const MovementSection = ({ className = "" }) => {
        return (
            <div className={`space-y-6 text-center md:text-left ${className}`}>
                <p className="text-gray-300 text-2xl">
                    Whether you're looking for consistent returns, automated trading, or a smart risk managed system,
                    <span className="font-bold"> PROFITPULSE </span> is the ultimate EA for modern traders.
                    <br /><br />
                    Join the movement. | Smart Trading, Smarter Returns
                </p>
                <div className="mt-6 flex justify-center md:justify-start">
                    <a href='/#contact-section' className="rounded-full bg-[#87A3A8] px-6 py-3 text-sm font-medium text-black shadow-md transition hover:bg-opacity-80">CONTACT US</a>
                </div>
            </div>
        )
    }
    return (
        <div className='container mx-auto px-4'>
            <section className="flex flex-col items-center justify-center lg:space-y-16 space-y-10 px-6 pb-10 lg:pb-16 text-white">

                <MovementSection className={'md:hidden'} />

                <Heading title={"How It Works"} />
                <div className="grid md:grid-cols-2 gap-16 items-center">
                    <div className="space-y-6">
                        {steps.map((step, index) => (
                            <div key={index}>
                                <p className="font-bold mb-1 text-center md:text-left">{step.step}</p>
                                <div className="rounded-lg liner-border text-center md:text-left">
                                    <div className="px-4 py-6 bg-[#706464] bg-opacity-[0.14] ">
                                        <h3 className="text-xl font-bold mt-1">{step.title}</h3>
                                        <p className="mt-2">{step.description}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <MovementSection className={'hidden md:block'} />

                </div>
            </section>
        </div>

    );
}
