import React from 'react';
import FrontLayout from '../components/FrontLayout';
import Header from '../components/FrontLayout/Header';
import HeroSection from '../components/ProductPage/HeroSection';
import SmartChoice from '../components/ProductPage/SmartChoice';

const PammPage = () => {

  return (
    <>
      <FrontLayout>
        <div className=" text-white relative pamm-hero-bg">
          <div className='container mx-auto lg:px-4 px-0'>
            <Header />
            <HeroSection title='Introducing the PROFITPULSE EA PAMM Account – A Smarter Way to Invest in Forex' subtitle={`Looking for a hands-off approach to forex trading with professional management and built-in safety? The PROFITPULSE EA PAMM (Percentage Allocation Management Module) account offers a seamless way to invest while letting experienced money managers trade on your behalf without them ever having direct access to your funds.`} />
          </div>
        </div>

        <SmartChoice />
        
      </FrontLayout>
    </>
  );
};

export default PammPage;