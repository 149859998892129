import React from 'react'
import Heading from './Heading'

export default function OverviewSection() {
    return (
        <div className="w-full overview-bg" id='sec-overview'>
            <div className="container mx-auto px-4 py-16">
                <Heading title={"Overview of the benefits"} />

                <div className="grid grid-cols-2 mt-7 gap-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4">
                    <div className="overview-item-1 min-h-[230px] lg:min-h-[330px] relative rounded-3xl shadow-lg overflow-hidden">
                        <div className='absolute inset-0 overview-item-overlay'></div>
                        <h2 className="relative z-10 text-center text-md font-light text-white lg:text-2xl p-6">Fully Automated Trading</h2>
                    </div>

                    <div className="overview-item-2 min-h-[230px] lg:min-h-[330px] relative rounded-3xl shadow-lg overflow-hidden">
                        <div className='absolute inset-0 overview-item-overlay'></div>
                        <h2 className="z-10 w-100 absolute bottom-0 left-0 right-0 mx-auto text-center text-md font-light text-white lg:text-2xl p-6"> Consistent <br /> performance without manual intervention </h2>
                    </div>

                    <div className="overview-item-1 min-h-[230px] lg:min-h-[330px] relative rounded-3xl shadow-lg overflow-hidden">
                        <div className='absolute inset-0 overview-item-overlay'></div>
                        <h2 className="relative z-10 text-center text-md font-light text-white lg:text-2xl p-6">Precision, low drawdowns, and reliable results</h2>
                    </div>

                    <div className="overview-item-2 min-h-[230px] lg:min-h-[330px] relative rounded-3xl shadow-lg overflow-hidden">
                        <div className='absolute inset-0 overview-item-overlay'></div>
                        <h2 className="z-10 w-100 absolute bottom-0 left-0 right-0 mx-auto text-center text-md font-light text-white lg:text-2xl p-6"> Focus on account safety and high-quality trade execution </h2>
                    </div>

                </div>

                <div className="mt-12 flex justify-center items-center gap-4 flex-col md:flex-row">
                    <a href="/dashboard" className="bg-[#89A8B0] hover:bg-[#89a8b0e5] text-black lg:text-md inline-flex rounded-3xl px-6 py-3 text-sm transition duration-300">INVESTOR DASHBOARD</a>
                    <a href="#contact-section" className="border-light-600 hover:bg-[#89a8b0e5] lg:text-md inline-flex rounded-3xl border px-6 py-3 text-sm text-white hover:text-black hover:border-black transition duration-300">CONTACT US</a>
                </div>
            </div>
        </div>
    )
}
