import React, { useState, useEffect } from 'react';
import {
  TextField,
  Button,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Chip,
  Autocomplete,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { getAllFeatures } from '../services/featureService';

const SubscriptionForm = ({ subscriptionData, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [formValues, setFormValues] = useState({
    name: '',
    price: 0,
    features: [], // Ensure features is always initialized as an array
    active: true,
  });

  const [availableFeatures, setAvailableFeatures] = useState([]);

  useEffect(() => {
    fetchFeatures();
    if (subscriptionData) {
      setFormValues({
        ...subscriptionData,
        features: Array.isArray(subscriptionData.features)
          ? subscriptionData.features
          : JSON.parse(subscriptionData.features || '[]'),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionData]);

  const fetchFeatures = async () => {
    try {
      const features = await getAllFeatures("subscription");
      setAvailableFeatures(features);
    } catch (error) {
      enqueueSnackbar('Error fetching features', { variant: 'error' });
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFeatureChange = (event, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      features: value, // Store the full feature objects
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await onSubmit(formValues);
      enqueueSnackbar('Subscription saved successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error saving subscription', { variant: 'error' });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
      <Typography variant="h5" gutterBottom>
        {subscriptionData?.id ? 'Edit Subscription' : 'Create Subscription'}
      </Typography>
      <TextField
        label="Name"
        name="name"
        value={formValues.name}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <TextField
        label="Price"
        name="price"
        type="number"
        value={formValues.price}
        onChange={handleChange}
        fullWidth
        margin="normal"
        required
      />
      <Autocomplete
        multiple
        options={availableFeatures}
        getOptionLabel={(option) => `${option.name || 'Unnamed'} (${option.value || 'No Value'})`}
        value={formValues.features.map((feature) =>
          availableFeatures.find(
            (f) => f.id === feature.id
          ) || feature
        )}
        onChange={handleFeatureChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={`${option.name || 'Unnamed'} (${option.value || 'No Value'})`}
              {...getTagProps({ index })}
              key={option.id}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Features"
            placeholder="Select features"
            margin="normal"
          />
        )}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={formValues.active}
            onChange={handleChange}
            name="active"
          />
        }
        label="Active"
      />
      <Box sx={{ mt: 2 }}>
        <Button variant="contained" color="primary" type="submit">
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionForm;
