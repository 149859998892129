import * as React from 'react';
import { useEffect, useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
import { getRiskStatus } from '../services/riskService';

// Enum for RiskLevel
export const RiskLevel = {
  High: 30,
  Medium: 20,
  Low: 10,
  None: 0,
  DieTryin: -1
};

const RiskStatusCard = ({ riskLevel, hoursIntoNextEvent = 0, hoursFromLastEvent = 0 }) => {
  const [isRisky, setIsRisky] = useState(false);
  const [enableTrading, setEnableTrading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [lastEventTime, setLastEventTime] = useState(0);
  const [nextEventTime, setNextEventTime] = useState(0);

  useEffect(() => {
    const fetchRiskData = async () => {
      try {
        const response = await getRiskStatus({
          impacts: [],
          currencies: [], 
          hoursIntoNextEvent: hoursIntoNextEvent,
          hoursFromLastEvent: hoursFromLastEvent,
          maxVixScore: 0,
          riskLevel: riskLevel, // Pass the riskLevel prop to API call
        });
        setIsRisky(response.isRisky);
        setEnableTrading(response.enableTrading);
        setLastEventTime(response.lastEventInHours * 3600); // Convert hours to seconds
        setNextEventTime(response.nextEventInHours * 3600); // Convert hours to seconds
      } catch (error) {
        console.error('Error fetching risk status:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskData();

    const riskInterval = setInterval(fetchRiskData, 10 * 60 * 1000); // Fetch every 10 mins
    return () => clearInterval(riskInterval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [riskLevel]);

  useEffect(() => {
    if (lastEventTime > 0 || nextEventTime > 0) {
      const countdownInterval = setInterval(() => {
        setLastEventTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setNextEventTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [lastEventTime, nextEventTime]);

  // Helper to get risk level label
  const getRiskLevelLabel = () => {
    switch (riskLevel) {
      case RiskLevel.High:
        return 'Conservative';
      case RiskLevel.Medium:
        return 'Balanced';
      case RiskLevel.Low:
        return 'Moderate';
      case RiskLevel.None:
        return 'Aggressive';
      default:
        return "Die Tryin'";
    }
  };

  return (
    <Box width={{ xs: '100%', md: '100%', lg: '100%' }} mb={1}>
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: isRisky ? 'red' : 'green',
          color: 'white',
          borderRadius: 2,
          boxShadow: 3
        }}
      >
        {loading ? (
          <Typography variant="h5">Loading...</Typography>
        ) : (
          <>
            {/* Risk Level Header */}
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                {getRiskLevelLabel()} Mode
              </Typography>
            </Box>            
            <Box display="flex" flexDirection="column" gap={1}>                       
                {(!enableTrading || isRisky) && (
                <>
                <Box>
                  <Typography variant='body1'>
                    <strong>Trade entry suspended!</strong>
                  </Typography>
                </Box>
                </>
                )}    
                {(!isRisky) && (
                <>
                <Box>
                  <Typography variant='body1'>
                    <strong>Trade entry active!</strong>
                  </Typography>
                </Box>
                </>
                )}              
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default RiskStatusCard;
