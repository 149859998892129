import React from 'react'

export default function Heading({ title, dashTitle, desc }) {
    return (
        <div className="flex flex-col items-center space-y-4 mb-10">
            {dashTitle && (
                <div className="flex items-center space-x-2 mb-4">
                    <span className="block h-[2px] w-[53px] bg-[#89A8B0]"></span>
                    <h1 className="lg:text-left text-center text-sm m-0 p-0 font-bold uppercase tracking-wide text-white">{dashTitle}</h1>
                </div>
            )}
            {title && (
                <h2 className="!m-0 !p-0 lg:text-5xl text-3xl lg:text-left text-center font-semibold text-white">{title}</h2>
            )}
            {desc && (
                <p class="text-gray-400 mt-2">{desc}</p>
            )}
        </div>
    )
}
