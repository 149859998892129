import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
  IconButton,
  TablePagination,
  Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getAllSubscriptions, deleteSubscription } from '../services/subscriptionService';
import { getUserFromToken } from '../services/authService';

const SubscriptionList = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSubscriptions, setFilteredSubscriptions] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const user = getUserFromToken(); // Get current user's roles
  const isSysAdmin = user?.roles?.includes('sys-admin'); // Check if the user is a sys-admin

  useEffect(() => {
    fetchSubscriptions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchSubscriptions = async () => {
    try {
      const data = await getAllSubscriptions();
      const parsedSubscriptions = data.map((sub) => ({
        ...sub,
        features: JSON.parse(sub.features || '[]'),
      }));
      const sortedSubscriptions = parsedSubscriptions.sort((a, b) =>
        a.name?.localeCompare(b.name)
      );
      setSubscriptions(sortedSubscriptions);
      setFilteredSubscriptions(sortedSubscriptions);
    } catch (error) {
      enqueueSnackbar('Error fetching subscriptions', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this subscription?');
    if (confirmed) {
      try {
        await deleteSubscription(id);
        fetchSubscriptions();
        enqueueSnackbar('Subscription deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting subscription', { variant: 'error' });
      }
    }
  };

  const handleSearch = (e) => {
    const term = e.target?.value?.toLowerCase() || '';
    setSearchTerm(term);
    const filtered = subscriptions.filter(
      (sub) =>
        sub.name?.toLowerCase().includes(term) ||
        sub.features.some((feature) =>
          feature.name?.toLowerCase().includes(term)
        )
    );
    setFilteredSubscriptions(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper elevation={3} sx={{ padding: '20px', margin: '20px' }}>
      {/* Search Bar */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" sx={{ width: '70%' }}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <TextField
            placeholder="Search by name or features"
            inputProps={{ 'aria-label': 'search subscriptions' }}
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/subscriptions/create')}
            sx={{ marginRight: '10px' }}
          >
            Add Subscription
          </Button>
          <IconButton onClick={fetchSubscriptions}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="subscription table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Features</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSubscriptions
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((sub) => (
                <TableRow key={sub.id}>
                  <TableCell>{sub.name}</TableCell>
                  <TableCell>${sub.price}</TableCell>
                  <TableCell>
                    <ul>
                      {sub.features.map((feature) => (
                        <li key={feature.id}>{feature.name || 'Unnamed Feature'}</li>
                      ))}
                    </ul>
                  </TableCell>
                  <TableCell>{new Date(sub.createdAt).toLocaleString()}</TableCell>
                  <TableCell>{new Date(sub.updatedAt).toLocaleString()}</TableCell>
                  <TableCell>{sub.active ? 'Yes' : 'No'}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton
                        color="primary"
                        onClick={() => navigate(`/subscriptions/edit/${sub.id}`)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    {/* Render Delete only for sys-admin */}
                    {isSysAdmin && (
                      <Tooltip title="Delete">
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(sub.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredSubscriptions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default SubscriptionList;