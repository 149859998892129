import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FrontLayout from '../components/FrontLayout';
import Header from '../components/FrontLayout/Header';
import HeroSection from '../components/LandingPage/HeroSection';
import OverviewSection from '../components/LandingPage/OverviewSection';
import FeatureSection from '../components/LandingPage/FeatureSection';
import AdvisorSection from '../components/LandingPage/AdvisorSection';
import TestimonialSection from '../components/LandingPage/TestimonialSection';
import ContactUsSection from '../components/LandingPage/ContactUsSection';

const LandingPage = () => {
  
  const location = useLocation();
  useEffect(() => {
    if (location.hash === "#contact-section") {
      const contactSection = document.getElementById("contact-section");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
    if (location.hash === "#sec-overview") {
      const benefitSection = document.getElementById("sec-overview");
      if (benefitSection) {
        benefitSection.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]); 
  return (
    <>
      <FrontLayout>
        <div className="text-white relative">
          <div className='container mx-auto lg:px-4 px-0'>
            <Header />
            <HeroSection />
          </div>
          <div className="absolute inset-0 hero-bg hidden lg:block"></div>
          <div className="absolute inset-0 hero-mob-bg lg:hidden"></div>
          <div className="absolute inset-0 hero-mask hidden lg:block"></div>
        </div>

        <OverviewSection id='sec-overview'/>
        <FeatureSection id='feature-section'/>
        <AdvisorSection id='products-section'/>
        <TestimonialSection />
        <ContactUsSection id='contact-section'/>
      </FrontLayout>
    </>
  );
};

export default LandingPage;
