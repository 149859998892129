import React from 'react'

export default function HeroSection({ title = "", subtitle = ""}) {
    return (
        <section className="flex lg:flex-row flex-col-reverse items-start justify-between pt-0 lg:pt-16 pb-0 lg:pb-16" id='hero-section'>
            <div className="lg:w-2/5 md:w-full w-full text-center lg:text-left lg:px-0 px-5 relative sm:hero-bg pb-16 lg:pb-0">
                <h1 className="text-3xl lg:text-6xl font-bold">
                    {title}
                </h1>
                <p className="mt-6 text-gray-400">
                    {subtitle}
                </p>
                <a href='/#contact-section' className="bg-[#89A8B0] hover:bg-[#89a8b0e5] px-10 py-3 inline-flex mt-6 rounded-full text-black font-medium">
                    Contact us
                </a> 
                <div className="absolute inset-0 hero-mob-mask lg:hidden"></div>
            </div>
            <div className="lg:w-3/5 md:w-full w-full flex justify-center mb-[80vw] md:mb-0">
                
            </div>
        </section>
    )
}
