import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
  TablePagination,
  Button,
  ButtonGroup,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Modal,
} from '@mui/material';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  Replay as ReplayIcon,
  Sync as SyncIcon,
  ContentCopy as ContentCopyIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import {
  getAllLicenseRecords,
  getLicenseRecord,
  deleteLicenseRecord,
  updateLicenseRecord,
  getLicenseKeyInfo,
} from '../services/licenseRecordService';
import { generateNewKey } from '../services/licenseService';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const LicenseRecordList = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [licenseRecords, setLicenseRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(null);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [viewLicenseInfo, setViewLicenseInfo] = useState(null);
  const [openViewModal, setOpenViewModal] = useState(false);

  useEffect(() => {
    fetchLicenseRecords();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLicenseRecords = async () => {
    setLoading(true);
    try {
      const records = await getAllLicenseRecords();
      setLicenseRecords(records);
      setFilteredRecords(records);
    } catch (error) {
      enqueueSnackbar('Error fetching license records', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = licenseRecords.filter((record) =>
      record.userFullName?.toLowerCase().includes(term) ||
      record.applicationName?.toLowerCase().includes(term) ||
      record.subscriptionName?.toLowerCase().includes(term) ||
      record.licenseBlob?.associatedAccount?.toLowerCase().includes(term)
    );
    setFilteredRecords(filtered);
  };

  const handleDelete = async () => {
    try {
      await deleteLicenseRecord(selectedRecord.id);
      enqueueSnackbar('License record deleted successfully', { variant: 'success' });
      fetchLicenseRecords();
    } catch (error) {
      enqueueSnackbar('Error deleting license record', { variant: 'error' });
    } finally {
      setDialogOpen(false);
      setSelectedRecord(null);
    }
  };

  const handleSyncLicence = async (record = null) => {
      const recordDetails = await getLicenseRecord(record.id); // Fetch record details by ID
      const payload = {
        userId: recordDetails.userId || '',
        applicationId: recordDetails.applicationId || '',
        subscriptionId: recordDetails.subscriptionId || '',
        licenseKey: recordDetails.licenseBlob?.key || '',
        brokerageAccountNumber: recordDetails.licenseBlob?.associatedAccount || '',
        status: recordDetails.active ? 1 : 0,
        expiresOn: recordDetails.licenseBlob?.expiresOn || '',
      };
  
      try{
        await updateLicenseRecord(record.id, payload); // Call updateLicenseRecord for editing
          enqueueSnackbar('License record synced successfully', { variant: 'success' });
      }catch (error) {
        enqueueSnackbar('Error syncing license record', { variant: 'error' });
      }
    };

  const handleRegenerateKey = async () => {
    try {
      await generateNewKey(selectedRecord.licenseBlobId);
      enqueueSnackbar('License key regenerated successfully', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar('Error regenerating license key', { variant: 'error' });
    } finally {
      setDialogOpen(false);
      setSelectedRecord(null);
    }
  };

   const handleViewLicenseInfo = async (id) => {
      try {
        const data = await getLicenseKeyInfo(id);
        setViewLicenseInfo(data);
        setOpenViewModal(true);
      } catch (error) {
        enqueueSnackbar('Error fetching license info', { variant: 'error' });
      }
    };
  
    const handleCopyLicenseKey = () => {
      if (viewLicenseInfo && viewLicenseInfo?.key) {
        navigator.clipboard.writeText(viewLicenseInfo?.key);
        enqueueSnackbar('License key copied to clipboard');
      }
    };
  
    const handleCopyAccountNumber = () => {
      if (viewLicenseInfo && viewLicenseInfo?.associatedAccount) {
        navigator.clipboard.writeText(viewLicenseInfo?.associatedAccount);
        enqueueSnackbar('Account number copied to clipboard');
      }
    };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar('Copied to clipboard');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseModal = () => {
    setOpenViewModal(false);
    setSelectedRecord(null);
    setViewLicenseInfo(null);
  };

  return (
    <Paper sx={{ mt: 3, p: 2 }}>
      <Typography variant="h6" gutterBottom>
        All License Records
      </Typography>
      <TextField
        label="Search name, account #, application and subscription"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearch}
      />
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Application</TableCell>
                <TableCell>Subscription</TableCell>
                <TableCell>Account #</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Expired On</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((record) => (
                <TableRow key={record.id}>
                  <TableCell>{record.userFullName}</TableCell>
                  <TableCell>{record.applicationName}</TableCell>
                  <TableCell>{record.subscriptionName}</TableCell>
                  <TableCell>{record.licenseBlob?.associatedAccount}</TableCell>
                  <TableCell>{record.active ? 'Active' : 'Inactive'}</TableCell>
                  <TableCell>{new Date(record.createdAt).toLocaleDateString()}</TableCell>
                  <TableCell>{new Date(record.licenseBlob?.expiresOn).toLocaleDateString()}</TableCell>
                  <TableCell>
                    <ButtonGroup variant="outlined">
                      <Tooltip title="Copy Key">
                        <IconButton onClick={() => handleCopy(record.licenseBlob.key)}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="View License Info">
                        <IconButton onClick={() => handleViewLicenseInfo(record.id)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>                      
                      <Tooltip title="Regenerate Key">
                        <IconButton onClick={() => {
                          setDialogAction('regenerate');
                          setSelectedRecord(record);
                          setDialogOpen(true);
                        }}>
                          <ReplayIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Sync License">
                        <IconButton onClick={() => handleSyncLicence(record)}>
                          <SyncIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Delete">
                        <IconButton onClick={() => {
                          setDialogAction('delete');
                          setSelectedRecord(record);
                          setDialogOpen(true);
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Edit">
                        <IconButton color="primary" onClick={() => navigate(`/license-records/edit/${record.id}`)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      {viewLicenseInfo && (
        <Modal open={openViewModal} onClose={handleCloseModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              License Info
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography><strong>Key:</strong> {viewLicenseInfo?.key}</Typography>
              <IconButton onClick={handleCopyLicenseKey}>
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <Typography><strong>Account:</strong>
              {viewLicenseInfo?.associatedAccount}
              <IconButton onClick={handleCopyAccountNumber}>
                <ContentCopyIcon />
              </IconButton>
             </Typography>
            <Typography><strong>Description:</strong> {viewLicenseInfo?.description}</Typography>
            <Typography><strong>Validate Equity Size:</strong> {viewLicenseInfo?.validateEquitySize ? 'Yes' : 'No'}</Typography>
            <Typography><strong>Equity Size Cap:</strong> {viewLicenseInfo?.validateEquitySize ? viewLicenseInfo?.equitySize : 'No Limit' }</Typography>
            <Typography><strong>License To:</strong> {viewLicenseInfo?.licenseToName}</Typography>
            <Typography><strong>Email:</strong> {viewLicenseInfo?.licenseToEmail}</Typography>
            <Typography><strong>Expires On:</strong> {new Date(viewLicenseInfo?.expiresOn).toLocaleDateString()}</Typography>
            <Typography><strong>Status:</strong> {viewLicenseInfo?.active ? 'Active' : 'Inactive'}</Typography>
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={handleCloseModal} variant="contained" color="primary">
                Close
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <DialogTitle>{dialogAction === 'delete' ? 'Confirm Delete' : 'Confirm Regeneration'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {dialogAction === 'delete' ? 'Are you sure you want to delete this license record?' : 'Are you sure you want to regenerate the license key?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={dialogAction === 'delete' ? handleDelete : handleRegenerateKey}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LicenseRecordList;
