import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  TextField,
  IconButton,
  TablePagination,
  MenuItem,
  Select,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getAllFeatures, deleteFeature } from '../services/featureService';

const FeatureList = () => {
  const [features, setFeatures] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedScope, setSelectedScope] = useState('all');

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeatures();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFeatures = async () => {
    try {
      const data = await getAllFeatures();
      const sortedFeatures = data.sort((a, b) => a.name.localeCompare(b.name));
      setFeatures(sortedFeatures);
      setFilteredFeatures(sortedFeatures);
    } catch (error) {
      enqueueSnackbar('Error fetching features', { variant: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this feature?');
    if (confirmed) {
      try {
        await deleteFeature(id);
        fetchFeatures();
        enqueueSnackbar('Feature deleted successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar('Error deleting feature', { variant: 'error' });
      }
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    filterFeatures(term, selectedScope);
  };

  const handleScopeChange = (e) => {
    const scope = e.target.value;
    setSelectedScope(scope);
    filterFeatures(searchTerm, scope);
  };

  const filterFeatures = (search, scope) => {
    let filtered = features.filter(
      (feature) =>
        feature.name.toLowerCase().includes(search) ||
        feature.value.toString().toLowerCase().includes(search)
    );

    if (scope !== 'all') {
      filtered = filtered.filter((feature) => feature.scope.toLowerCase() === scope);
    }

    setFilteredFeatures(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Paper elevation={3} sx={{ padding: '20px', margin: '20px' }}>
      {/* Search and Scope Selection */}
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
        <Box display="flex" alignItems="center" sx={{ width: '60%' }}>
          <IconButton>
            <SearchIcon />
          </IconButton>
          <TextField
            placeholder="Search by name or value"
            inputProps={{ 'aria-label': 'search features' }}
            value={searchTerm}
            onChange={handleSearch}
            fullWidth
          />
        </Box>
        <Select
          value={selectedScope}
          onChange={handleScopeChange}
          displayEmpty
          sx={{ width: '200px', marginLeft: '10px' }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="application">Application</MenuItem>
          <MenuItem value="subscription">Subscription</MenuItem>
          <MenuItem value="global">Global</MenuItem>
          <MenuItem value="user">User</MenuItem>
        </Select>
        <Box display="flex" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/features/create')}
            sx={{ marginRight: '10px' }}
          >
            Add Feature
          </Button>
          <IconButton onClick={fetchFeatures}>
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="feature table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Value</TableCell>
              <TableCell>Scope</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Updated</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFeatures
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((feature) => (
                <TableRow key={feature.id}>
                  <TableCell>{feature.name}</TableCell>
                  <TableCell>{String(feature.value)}</TableCell>
                  <TableCell>{String(feature.scope)}</TableCell>
                  <TableCell>{new Date(feature.created).toLocaleString()}</TableCell>
                  <TableCell>{new Date(feature.updated).toLocaleString()}</TableCell>
                  <TableCell>
                    <IconButton color="primary" onClick={() => navigate(`/features/edit/${feature.id}`)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton color="secondary" onClick={() => handleDelete(feature.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={filteredFeatures.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default FeatureList;
